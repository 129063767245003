// eslint-disable-next-line no-redeclare,no-unused-vars
function loading(on, overlay) {
  const {title} = window;
  if (on) {
    if ($(document.body).hasClass('loading')) return;
    $(document.body).addClass('loading');
    if (overlay) $(document.body).addClass('overlay');
    if (mobile != 'smartphone') $('#info i').removeClass('fa-window-maximize');

    var i = 0;
    var symbols = ['◷', '◶', '◵', '◴'];
    var next = function () {
      setTitle(symbols[i] + ' ' + title);
      i++;
      if (i > 3) i = 0;
    };
    next();
    window.titleLoader = setInterval(next, 500);
  } else {
    $(document.body).removeClass('loading').removeClass('overlay');
    if (mobile != 'smartphone') $('#info i').addClass('fa-window-maximize');
    const {titleLoader} = window;
    if (titleLoader) {
      clearInterval(titleLoader);
      setTitle(title);
    }
  }
}
